import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import api from "../api/axios";
import { Page } from "../components/custom";
import TotalVolumeCard from "../components/custom/CustomVolumeCards/TotalVolumeCard";
import { getApiFormattedDate } from "../helpers/utilityFunctions";
import { usePermissions } from "../hooks/usePermissions";
import { TransactionIndicatorsCard } from "../modules/Dashboard";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectAuthState } from "../store/slices/authSlice";
import { getIndicatorTransactionsAsync } from "../store/slices/indicatorTransactionSlice";

export default function PayoutPage() {
  const dispatch = useAppDispatch();
  const [searchQuery] = useState("");
  const [volume, setVolume] = useState("");
  const [total, setTotal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isPermitted = usePermissions("qr");
  const authState = useAppSelector(selectAuthState);

  const handleIndicatorChange = (startDate: string) => {
    dispatch(
      getIndicatorTransactionsAsync({
        page: 1,
        startDate,
        endDate: getApiFormattedDate(new Date()),
        type: "payout",
      })
    );
  };

  const { data } = useAppSelector((state) => state.indicatorTransactionsData);

  const transactions = {
    all: data.total,
    pending: data.pending,
    failed: data.failed,
    successful: data.successful,
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setIsLoading(true);

        const response = await api.get("/payout/transactions");

        const data = response.data;
        const volume = data.data.volume;

        const total = data.data.total;

        setVolume(volume);
        setTotal(total);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching deposit transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  if (!authState.user) {
    return <Navigate to="/" />;
  }

  if (!authState.user && !isPermitted) {
    return <Navigate to={"/not-found"} />;
  }

  return (
    <Page title="Payouts">
      <section>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Heading
              as="h1"
              fontWeight={400}
              fontSize="1.5rem"
              lineHeight="133%"
              letterSpacing="-0.1px"
              mb={1}
              maxW="335px"
            >
              Payouts Overview
            </Heading>
            <Text color="gray.300" maxW="335px">
              Find the Payouts Overview made on Bet9ja
            </Text>
          </Box>
        </Stack>
      </section>

      <section style={{ marginTop: "32px" }}>
        <Stack spacing={6}>
          <Stack direction={{ base: "column", xl: "row" }} spacing={6}>
            <Box as="section" flex="6" width={{ base: "100%", xl: "auto" }}>
              <TotalVolumeCard
                searchQuery={searchQuery}
                volume={volume}
                isLoading={isLoading}
                eyeHoverBackground="#B794F4"
                isAmount
              />
            </Box>
            <Box as="section" flex="6" width={{ base: "100%", xl: "auto" }}>
              <TotalVolumeCard
                searchQuery={searchQuery}
                volume={total}
                isLoading={isLoading}
                backgroundColor="#ffeceb"
                cardTitle="Total volume all time"
                eyeBackground="#feb2b2"
                eyeHoverBackground="#fc8181"
                startLoadingColor="#ffeceb"
                endLoadingColor="#ffded7"
                walletIconColor="#FEB2B2"
              />
            </Box>
          </Stack>
          <Box as="section">
            <TransactionIndicatorsCard
              title="Transaction Indicators"
              searchQuery={searchQuery}
              transactions={transactions}
              onChange={handleIndicatorChange}
            />
          </Box>
        </Stack>
      </section>
    </Page>
  );
}
