import CustomTable from "../../components/custom/CustomTable/CustomTable";
import {
  formatCamelCaseToWords,
  formatDate,
} from "../../helpers/utilityFunctions";

interface IMonthlyWithdrawalsProps {
  data: {
    id: string;
    name: string;
    module: string;
    type: string;
    url: string;
    date: string;
    createdAt: string;
    updatedAt: string;
  }[];
  isLoading: boolean;
  isSuccess: boolean;
  page: number;
  limit: number;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
export default function MonthlyReports({
  data,
  isLoading,
  isSuccess,
  page,
  setPage,
  limit,
  total,
}: IMonthlyWithdrawalsProps) {
  const sortedData = data
    ? [...data]?.sort((a, b) => {
        const dateA = new Date(a?.date ?? "");
        const dateB = new Date(b?.date ?? "");

        return dateB.getTime() - dateA.getTime();
      })
    : [];

  const formattedReports = sortedData?.map((report) => ({
    key: report.id,
    name: report.name,
    module: formatCamelCaseToWords(report.module),
    type: report.type,
    date: formatDate(report.date ?? ""),
  }));

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <>
      <CustomTable
        columns={["Name", "Module", "Type", "Date", ""]}
        items={formattedReports}
        isLoading={isLoading}
        isSuccess={isSuccess}
        page={page}
        onPageChange={handlePageChange}
        actions={[
          {
            text: "Download Statement",
            onClickFunction: (key) => {
              window.location.href = data?.filter(
                (report) => report.id === key
              )?.[0]?.url;
            },
          },
        ]}
        limit={limit}
        total={total}
      />
    </>
  );
}
