import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { Page } from "../components/custom";
import PayoutData from "../modules/Payouts/PayoutData";

const PayoutHistoryPage = () => {
  return (
    <Page title="Payout History">
      <section>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Heading
              as="h1"
              fontWeight={400}
              fontSize="1.5rem"
              lineHeight="133%"
              letterSpacing="-0.1px"
              mb={1}
              maxW="335px"
            >
              Payout History
            </Heading>
            <Text color="gray.300" maxW="335px">
              Find the Payouts made on Bet9ja
            </Text>
          </Box>
        </Stack>
      </section>
      <Box
        mt={5}
        background="#fff"
        border="1px solid #e0e3e6"
        borderRadius="24px"
        padding={6}
        minH="272px"
        height="100%"
      >
        <PayoutData />
      </Box>
    </Page>
  );
};

export default PayoutHistoryPage;
