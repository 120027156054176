import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import {
  CustomHighlight,
  CustomOptionSelector,
} from "../../../components/custom";
import {
  InformationCircleOutlineIcon,
  RectangleGroupWithBgIcon,
} from "../../../components/icons";
import { getApiFormattedDate } from "../../../helpers/utilityFunctions";
import Indicator from "./Indicator";

interface TransactionIndicatorsCardProps {
  title: string;
  backgroundColor?: string;
  searchQuery: string | string[];
  transactions?: {
    all: number;
    successful: number;
    pending: number;
    failed: number;
  };
  onChange: (startDate: string) => void;
}

export default function TransactionIndicatorsCard({
  title,
  backgroundColor,
  searchQuery,
  transactions,
  onChange,
}: TransactionIndicatorsCardProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialData, setIsInitialData] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedDataFilter, setSelectedDataFilter] = useState("day");

  const handleLoadingSuccessState = (
    loadingState: boolean,
    successState: boolean
  ) => {
    setIsLoading(loadingState);
    setIsSuccess(successState);
  };

  const handleSelectedDataFilterChange = useCallback(
    (option: string) => {
      if (option === selectedDataFilter && !isInitialData) {
        return;
      }

      const endDate = new Date();
      const startDate = new Date();

      handleLoadingSuccessState(true, false);
      setSelectedDataFilter(option);
      if (option === "week") {
        startDate.setDate(endDate.getDate() - 7);
      } else if (option === "month") {
        startDate.setDate(endDate.getDate() - 30);
      } else if (option === "day") {
        startDate.setDate(endDate.getDate());
      }

      onChange(getApiFormattedDate(startDate));
    },
    [onChange, selectedDataFilter, isInitialData]
  );

  useEffect(() => {
    if (isInitialData) {
      handleSelectedDataFilterChange("day");
      setIsInitialData(false);
    }
  }, [handleSelectedDataFilterChange, isInitialData]);

  useEffect(() => {
    if (transactions) {
      handleLoadingSuccessState(false, true);
    } else {
      setTimeout(() => {
        handleLoadingSuccessState(false, true);
      }, 1000);
    }
  }, [transactions]);

  return (
    <Box
      backgroundColor={backgroundColor}
      border="1px solid #e0e3e6"
      borderRadius="24px"
      padding={6}
      minH="272px"
      height="100%"
    >
      <HStack justifyContent="space-between" alignItems="center" mb="54px">
        <HStack spacing={{ base: "8px", md: "19px" }}>
          <RectangleGroupWithBgIcon boxSize="32px" />
          <Flex alignItems="self-start">
            <Heading
              as="h2"
              fontWeight={700}
              fontSize="1.125rem"
              lineHeight="133%"
              noOfLines={1}
            >
              <CustomHighlight query={searchQuery}>{title}</CustomHighlight>
            </Heading>
            <IconButton
              aria-label="Info"
              variant="ghost"
              icon={<InformationCircleOutlineIcon boxSize="20px" />}
              boxSize="25px"
              _hover={{
                background: "transparent",
              }}
            />
          </Flex>
        </HStack>

        <CustomOptionSelector
          options={["day", "week", "month"]}
          selectedOption={selectedDataFilter}
          setSelectedOption={handleSelectedDataFilterChange}
        />
      </HStack>

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={6}
        justifyContent="space-between"
        px={{ base: "initial", md: "24px" }}
        pr={{ base: "initial", xl: "148px" }}
      >
        <Indicator
          title="Total Transactions"
          amount={transactions?.all || 0}
          color="#39ace5"
          isLoading={isLoading}
          isSuccess={isSuccess}
          searchQuery={searchQuery}
          subtitle="Transactions Initiated"
          endLoadingColor="#f9fafa"
        />

        <Indicator
          title="Total Successful"
          amount={transactions?.successful || 0}
          color="#00c707"
          isLoading={isLoading}
          isSuccess={isSuccess}
          searchQuery={searchQuery}
          subtitle="Transactions Successful"
          endLoadingColor="#f9fafa"
        />

        <Indicator
          title="Total Pending"
          amount={transactions?.pending || 0}
          color="#ffaa08"
          isLoading={isLoading}
          isSuccess={isSuccess}
          searchQuery={searchQuery}
          subtitle="Transactions Pending"
          endLoadingColor="#f9fafa"
        />

        <Indicator
          title="Total Failed"
          amount={transactions?.failed || 0}
          color="#db4343"
          isLoading={isLoading}
          isSuccess={isSuccess}
          searchQuery={searchQuery}
          subtitle="Transactions Failed"
          endLoadingColor="#f9fafa"
        />
      </Stack>
    </Box>
  );
}
