import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { Page } from "../components/custom";
import ReportTable from "../modules/ActivityReport";

export default function PayoutReportPage() {
  return (
    <Page title="Payouts Reports">
      <section>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Heading
              as="h1"
              fontWeight={400}
              fontSize="1.5rem"
              lineHeight="133%"
              letterSpacing="-0.1px"
              mb={1}
              maxW="335px"
            >
              Payout Reports
            </Heading>
            <Text color="gray.300" maxW="335px">
              View all monthly and daily payout reports
            </Text>
          </Box>
        </Stack>
      </section>
      <section style={{ marginTop: "32px" }}>
        <Box as="section">
          <ReportTable module="Payout" />
        </Box>
      </section>
    </Page>
  );
}
