import {
  Box,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

import { CustomHighlight } from "../../../components/custom";
// import { DashboardButton } from "../../../components/custom/CustomButtons";
import {
  EyeOpenMiniIcon,
  EyeSlashIcon,
  WalletIconWithBg,
} from "../../../components/icons";
import {
  formatDateAndTime,
  formatNumber,
} from "../../../helpers/utilityFunctions";

interface AvailableBalanceCardProps {
  searchQuery: string | string[];
  netBalance: number;
  lastUpdatedDate: string;
  isLoading: boolean;
}

export default function AvailableBalanceCard({
  searchQuery,
  netBalance,
  lastUpdatedDate,
  isLoading,
}: AvailableBalanceCardProps) {
  const [showBalance, setShowBalance] = useState(true);

  const handleToggleBalanceVisibility = () => {
    setShowBalance((showBalance) => !showBalance);
  };

  const displayNetBalance = netBalance || 0.0;

  return (
    <Box
      background="green.100"
      border="1px solid #A8EEAA"
      borderRadius="24px"
      padding={6}
      minH="254px"
      // height="100%"
    >
      <HStack spacing={5} mb={9}>
        <WalletIconWithBg boxSize="32px" />

        <Heading as="h2" fontWeight={700} fontSize="1.125rem" lineHeight="133%">
          <CustomHighlight query={searchQuery}>
            Total Net Balance
          </CustomHighlight>
        </Heading>
      </HStack>

      <Box mb={4} display="flex" alignItems="center" gap="2px">
        <Text color="gray.200" fontSize=".75rem" lineHeight="133%">
          <CustomHighlight query={searchQuery}>Last Updated:</CustomHighlight>
        </Text>
        <Skeleton
          startColor="#ebfff0"
          endColor="#d0f7d4"
          borderRadius="12px"
          isLoaded={!isLoading}
          fadeDuration={1}
        >
          <Text
            color="gray.500"
            fontSize=".75rem"
            lineHeight="133%"
            fontWeight={500}
          >
            {formatDateAndTime(lastUpdatedDate)}
          </Text>
        </Skeleton>
      </Box>

      <HStack
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        mb="38px"
      >
        <Skeleton
          startColor="#ebfff0"
          endColor="#d0f7d4"
          borderRadius="12px"
          isLoaded={!isLoading}
          w="70%"
          fadeDuration={1}
        >
          <HStack
            color="#000"
            fontSize={{ base: "1.125rem", lg: "1.75rem" }}
            lineHeight="157%"
            alignItems="baseline"
            transition="filter 0.2s"
          >
            <Text>₦</Text>
            {showBalance ? (
              <>
                <Text
                  fontSize={{ base: "1.25rem", lg: "2.75rem" }}
                  lineHeight="132%"
                >
                  {formatNumber(Math.trunc(displayNetBalance)).formattedString}
                </Text>
                <Text>.{formatNumber(displayNetBalance).koboValue}</Text>
              </>
            ) : (
              <Text
                h={{ base: "26px", lg: "58px" }}
                fontSize={{ base: "1.25rem", lg: "2.75rem" }}
                lineHeight="132%"
              >
                <span style={{ verticalAlign: "sub" }}>******</span>
              </Text>
            )}
          </HStack>
        </Skeleton>

        <IconButton
          aria-label={showBalance ? "Hide Balance" : "Show Balance"}
          variant="solid"
          bg="green.200"
          borderRadius="50%"
          _hover={{
            bg: "green.300",
          }}
          onClick={handleToggleBalanceVisibility}
          icon={showBalance ? <EyeOpenMiniIcon /> : <EyeSlashIcon />}
        />
      </HStack>

      {/* <HStack gap={{ base: 2, md: 5 }}>
        <DashboardButton w="full" colorScheme="green">
          View Deposits
        </DashboardButton>
        <DashboardButton w="full" bg="#001419" _hover={{ bg: "gray.500" }}>
          View Withdrawals
        </DashboardButton>
      </HStack> */}
    </Box>
  );
}
