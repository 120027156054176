import { Box, BoxProps, Flex, Skeleton, Text } from "@chakra-ui/react";

import { CustomHighlight, PercentageChange } from "../../../components/custom";
import { formatNumber } from "../../../helpers/utilityFunctions";

interface AmountCardProps extends BoxProps {
  title: string;
  subtitle?: string;
  cardValue: number;
  background: string;
  searchQuery: string | string[];
  isLoading: boolean;
  isSuccess: boolean;
  startLoadingColor?: string;
  endLoadingColor?: string;
}

export default function AmountCard({
  title,
  subtitle,
  cardValue,
  background,
  searchQuery,
  isLoading,
  isSuccess,
  startLoadingColor,
  endLoadingColor,
  ...rest
}: AmountCardProps) {
  return (
    <Box borderRadius="18px" background={background} py={6} px={6} {...rest}>
      <Text
        color="gray.500"
        fontSize=".875rem"
        lineHeight="171%"
        textAlign="center"
        mb={4}
      >
        <CustomHighlight query={searchQuery}>{title}</CustomHighlight>
      </Text>

      <Skeleton
        startColor={startLoadingColor}
        endColor={endLoadingColor}
        borderRadius="12px"
        isLoaded={!isLoading && isSuccess}
        fadeDuration={1}
      >
        <Flex
          color="#000"
          fontSize="1.125rem"
          lineHeight="144%"
          textAlign="center"
          alignItems="baseline"
          justifyContent="center"
          mb={6}
        >
          <Text>₦</Text>
          <Text
            fontSize={{ base: "1.375rem", lg: "1.75rem" }}
            lineHeight="132%"
          >
            {formatNumber(Math.trunc(cardValue)).formattedString}
          </Text>
          <Text>.{formatNumber(cardValue).koboValue}</Text>
        </Flex>
      </Skeleton>

      <Skeleton
        startColor={startLoadingColor}
        endColor={endLoadingColor}
        borderRadius="12px"
        isLoaded={!isLoading && isSuccess}
        fadeDuration={1}
      >
        {/* <Box display="flex" gap={1} justifyContent="center">
          <PercentageChange prevValue={prevValue} currValue={currValue} />
          <Text fontSize=".875rem" lineHeight="114%">
            {subtitle}
          </Text>
        </Box> */}
      </Skeleton>
    </Box>
  );
}
