import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";

interface IndicatorSliceProps {
  page: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  type: "deposits" | "withdrawals" | "qr" | "payout";
}

type IndicatorStateType = {
  data: {
    total: number;
    successful: number;
    pending: number;
    failed: number;
  };
  status: string;
  error: string;
};

const initialData = { total: 0, successful: 0, pending: 0, failed: 0 };

const initialState: IndicatorStateType = {
  data: initialData,
  status: "idle",
  error: "",
};

export const getIndicatorTransactionsAsync = createAsyncThunk(
  "indicator/getTransactionsData",
  async (
    { startDate, endDate, type }: IndicatorSliceProps,
    { rejectWithValue }
  ) => {
    let url = `/${type}/transactions/metrics?`;
    if (startDate) {
      url += `startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }

    try {
      const { data } = await api.get(url);

      return {
        total: data.data.totalTransactions,
        pending: data.data.totalPending,
        failed: data.data.totalFailed,
        successful: data.data.totalSuccessful,
      };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

export const indicatorTransactionsSlice = createSlice({
  name: "indicator/getTransactionsData",
  initialState,
  reducers: {
    resetIndicatorTransactionsSlice: (state) => {
      state.data = initialData;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndicatorTransactionsAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getIndicatorTransactionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(getIndicatorTransactionsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetIndicatorTransactionsSlice } =
  indicatorTransactionsSlice.actions;

export default indicatorTransactionsSlice.reducer;
