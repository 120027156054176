import {
  Box,
  FormControl,
  Heading,
  HStack,
  Select,
  SkeletonText,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import DateRangeFilter from "../../components/custom/CustomDateRange/CustomDateRangeFilter";
import { QueueListWithBgIcon } from "../../components/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getReportsWithDownloadAsync,
  IGetReportsData,
} from "../../store/slices/reportsWithDownloadSlice";
import DailyReports from "./DailyReports";
import MonthlyReports from "./MonthlyReports";

export default function ReportTable({
  module,
}: {
  module:
    | "AccountStatement"
    | "Withdrawal"
    | "Deposit"
    | "Qr"
    | "VirtualAccount"
    | "Payout";
}) {
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(
    (state) => state.getReportsWithDownload
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState<IGetReportsData[]>();
  const [report, setReport] = useState<IGetReportsData[] | undefined>([]);
  const [totalReport, setTotalReport] = useState<number>();

  const handleFilterReports = (startDate: string, endDate: string) => {
    const filtered = data?.data?.data.filter(
      (d) => d.date >= startDate && d.date <= endDate
    );
    setFilteredData(filtered);
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    setPageSize(value);
    setPage(1);
  };

  const lastReportsTab = sessionStorage.getItem("lastReportsTab")
    ? Number(sessionStorage.getItem("lastReportsTab"))
    : 0;

  const [tabIndex, setTabIndex] = useState(lastReportsTab);

  const handleTabChange = (index: number) => {
    sessionStorage.setItem("lastReportsTab", index.toString());

    setTabIndex(index);
    if (index === 0) {
      dispatch(
        getReportsWithDownloadAsync({
          page: 1,
          limit: pageSize,
          module: module,
          type: "Monthly",
        })
      );
    } else {
      dispatch(
        getReportsWithDownloadAsync({
          page: 1,
          limit: pageSize,
          module: module,
          type: "Daily",
        })
      );
    }
  };

  useEffect(() => {
    setReport(
      filteredData && filteredData?.length > 0
        ? filteredData
        : filteredData && filteredData?.length === 0
        ? []
        : data?.data?.data
    );
    setTotalReport(
      filteredData && filteredData?.length > 0
        ? filteredData?.length
        : filteredData && filteredData?.length === 0
        ? 0
        : data?.data?.total
    );
  }, [data?.data?.data, filteredData, data?.data?.total]);

  useEffect(() => {
    if (!startDate && !endDate) {
      setReport(data?.data?.data);
      setTotalReport(data?.data?.total);
    }
  }, [data?.data?.data, endDate, startDate, data?.data?.total]);

  useEffect(() => {
    if (lastReportsTab === 0) {
      dispatch(
        getReportsWithDownloadAsync({
          page,
          limit: pageSize,
          module: module,
          type: "Monthly",
        })
      );
    } else {
      dispatch(
        getReportsWithDownloadAsync({
          page,
          limit: pageSize,
          module: module,
          type: "Daily",
        })
      );
    }
  }, [dispatch, lastReportsTab, page, pageSize, module]);

  return (
    <Box
      background="#fff"
      border="1px solid #e0e3e6"
      borderRadius="24px"
      padding={6}
      minH="272px"
      height="100%"
    >
      <Stack
        direction={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        mb="28px"
      >
        <HStack spacing={{ base: "8px", md: "18px" }}>
          <QueueListWithBgIcon boxSize="32px" />

          <Heading
            as="h2"
            fontWeight={700}
            fontSize={{ xs: "0.875rem", md: "1.125rem" }}
            lineHeight="133%"
            noOfLines={1}
          >
            {lastReportsTab === 0 ? "Monthly" : "Daily"} Reports History
          </Heading>
        </HStack>

        <Stack direction={{ base: "column", lg: "row" }} gap={4}>
          <Box>
            <FormControl>
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onSubmit={handleFilterReports}
                size="xs"
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" gap={4}>
            <SkeletonText noOfLines={2} isLoaded={status !== "loading"}>
              <Text fontWeight={500} lineHeight="250%">
                {totalReport ?? 0}{" "}
                {totalReport ?? 0 > 1 ? " reports" : " report"}
              </Text>
            </SkeletonText>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              size="sm"
              width="70px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Box>
        </Stack>
      </Stack>

      <Box mt={8}>
        <Tabs
          isLazy
          onChange={handleTabChange}
          index={tabIndex}
          defaultIndex={lastReportsTab}
        >
          <TabList>
            <Tab>Monthly</Tab>
            <Tab>Daily</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <MonthlyReports
                data={report ?? []}
                isLoading={status === "loading"}
                isSuccess={status === "success"}
                page={page}
                setPage={setPage}
                limit={pageSize}
                total={totalReport ?? 0}
              />
            </TabPanel>
            <TabPanel>
              <DailyReports
                data={report ?? []}
                isLoading={status === "loading"}
                isSuccess={status === "success"}
                page={page}
                setPage={setPage}
                limit={pageSize}
                total={totalReport ?? 0}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
