import { Box, Heading, HStack, Stack } from "@chakra-ui/react";

import { CustomHighlight } from "../../../components/custom";
import { ArrowTrendingUpWithBgIcon } from "../../../components/icons";
import AmountCard from "./AmountCard";

interface AccountOverviewCardProps {
  searchQuery: string | string[];
  accountCreditValue: number;
  accountDebitValue: number;
  isLoading: boolean;
}

export default function AccountOverviewCard({
  searchQuery,
  accountCreditValue,
  accountDebitValue,
  isLoading,
}: AccountOverviewCardProps) {
  return (
    <Box
      background="#fff"
      border="1px solid #e0e3e6"
      borderRadius="24px"
      padding={6}
      minH="254px"
      height="100%"
    >
      <HStack justifyContent="space-between" alignItems="center" mb="24px">
        <HStack spacing={{ base: "8px", md: "18px" }}>
          <ArrowTrendingUpWithBgIcon boxSize="32px" />

          <Heading
            as="h2"
            fontWeight={700}
            fontSize="1.125rem"
            lineHeight="133%"
            noOfLines={1}
          >
            <CustomHighlight query={searchQuery}>
              Account Overview
            </CustomHighlight>
          </Heading>
        </HStack>
      </HStack>

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 3, md: 6 }}
      >
        <AmountCard
          background="#f0ebff"
          cardValue={accountCreditValue}
          isLoading={isLoading}
          isSuccess={!isLoading}
          searchQuery={searchQuery}
          title="Total Credit"
          w="full"
          startLoadingColor="#f0ebff"
          endLoadingColor="#e3defc"
        />

        <AmountCard
          background="#ffeceb"
          cardValue={accountDebitValue}
          isLoading={isLoading}
          isSuccess={!isLoading}
          searchQuery={searchQuery}
          title="Total Debit"
          w="full"
          startLoadingColor="#ffeceb"
          endLoadingColor="#ffded7"
        />
      </Stack>
    </Box>
  );
}
