import { Box, BoxProps, SkeletonText, Text } from "@chakra-ui/react";

import { CustomHighlight } from "../../../components/custom";
import { formatNumber } from "../../../helpers/utilityFunctions";

interface IndicatorProps extends BoxProps {
  title: string;
  subtitle: string;
  amount: number;
  color: string;
  searchQuery: string | string[];
  isLoading: boolean;
  isSuccess: boolean;
  startLoadingColor?: string;
  endLoadingColor?: string;
}

export default function Indicator({
  title,
  subtitle,
  amount,
  color,
  searchQuery,
  isLoading,
  isSuccess,
  startLoadingColor,
  endLoadingColor,
  ...rest
}: IndicatorProps) {
  return (
    <Box {...rest}>
      <Text color="gray.300" fontSize=".875rem" lineHeight="171%" mb={6}>
        <CustomHighlight query={searchQuery}>{title}</CustomHighlight>
      </Text>

      <SkeletonText
        startColor={startLoadingColor}
        endColor={endLoadingColor}
        isLoaded={!isLoading && isSuccess}
        fadeDuration={1}
        skeletonHeight={3.5}
        spacing={3.5}
      >
        <Box borderLeft="2px solid" borderLeftColor={color} pl="4">
          <Text color="#000" fontSize="1.75rem" lineHeight="114%" mb={2}>
            {formatNumber(amount).formattedString}
          </Text>
          <Text
            color="gray.200"
            fontSize=".75rem"
            lineHeight="133%"
            maxW="84px"
          >
            <CustomHighlight query={searchQuery}>{subtitle}</CustomHighlight>
          </Text>
        </Box>
      </SkeletonText>
    </Box>
  );
}
