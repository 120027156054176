import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { AppLayout } from "../layouts";
import DailySweepPage from "../pages/DailySweepPage";
import DepositHistoryPage from "../pages/DepositHistoryPage";
import ForbiddenPage from "../pages/ForbiddenPage";
import PayoutHistoryPage from "../pages/PayoutHistoryPage";
import PayoutPage from "../pages/PayoutPage";
import PayoutReportPage from "../pages/PayoutReportPage";
import QRPaymentHistoryPage from "../pages/QRPaymentHistoryPage";
import VirtualAccountsPage from "../pages/VirtualAccountsPage";
import VirtualTransactionsPage from "../pages/VirtualTransactionsPage";
import WithdrawalHistoryPage from "../pages/WithdrawalHistoryPage";
import Loadable from "./Loadable";
import PrivateRoute from "./PrivateRoute";

// Lazy Imports
const NotFoundPage = Loadable(lazy(() => import("../pages/NotFoundPage")));
const SettingsPage = Loadable(lazy(() => import("../pages/SettingsPage")));
const DashboardPage = Loadable(lazy(() => import("../pages/DashboardPage")));
const DepositsPage = Loadable(lazy(() => import("../pages/DepositsPage")));
const WithdrawalsPage = Loadable(
  lazy(() => import("../pages/WithdrawalsPage"))
);
const QRPaymentsPage = Loadable(lazy(() => import("../pages/QRPaymentsPage")));
const ReportsPage = Loadable(lazy(() => import("../pages/ReportsPage")));
const FundingReportsPage = Loadable(
  lazy(() => import("../pages/FundingReportsPage"))
);
const AccountStatementPage = Loadable(
  lazy(() => import("../pages/AccountStatementPage"))
);
const WithdrawalReportPage = Loadable(
  lazy(() => import("../pages/WithdrawalReportPage"))
);
const DepositReportPage = Loadable(
  lazy(() => import("../pages/DepositReportPage"))
);
const QRPaymentsReportPage = Loadable(
  lazy(() => import("../pages/QRPaymentsReportPage"))
);
const VirtualAccountReportPage = Loadable(
  lazy(() => import("../pages/VirtualAccountsReportPage"))
);

// Route Object
const AppRoutes: RouteObject = {
  path: "/app",
  element: (
    <PrivateRoute>
      <AppLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: "",
      element: <Navigate to="/app/dashboard" />,
    },
    {
      path: "settings",
      element: <SettingsPage />,
    },
    {
      path: "dashboard",
      element: <DashboardPage />,
    },
    {
      path: "deposits",
      element: <DepositsPage />,
    },
    {
      path: "deposits-history",
      element: <DepositHistoryPage />,
    },
    {
      path: "withdrawals",
      element: <WithdrawalsPage />,
    },
    {
      path: "withdrawals-history",
      element: <WithdrawalHistoryPage />,
    },
    {
      path: "qr-payments",
      element: <QRPaymentsPage />,
    },
    {
      path: "qr-payments-history",
      element: <QRPaymentHistoryPage />,
    },
    {
      path: "payouts",
      element: <PayoutPage />,
    },
    {
      path: "payouts-history",
      element: <PayoutHistoryPage />,
    },
    {
      path: "reports",
      element: <ReportsPage />,
    },
    {
      path: "funding-reports",
      element: <FundingReportsPage />,
    },
    {
      path: "virtual-accounts",
      element: <VirtualAccountsPage />,
    },
    {
      path: "transactions",
      element: <VirtualTransactionsPage />,
    },
    {
      path: "daily-sweep",
      element: <DailySweepPage />,
    },
    {
      path: "account-statements",
      element: <AccountStatementPage />,
    },
    {
      path: "withdrawal-reports",
      element: <WithdrawalReportPage />,
    },
    {
      path: "deposits-reports",
      element: <DepositReportPage />,
    },
    {
      path: "qr-payments-reports",
      element: <QRPaymentsReportPage />,
    },
    {
      path: "payouts-reports",
      element: <PayoutReportPage />,
    },
    {
      path: "virtual-accounts-reports",
      element: <VirtualAccountReportPage />,
    },
    {
      path: "forbidden",
      element: <ForbiddenPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};

export default AppRoutes;
