import {
  Box,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

import {
  formatKoboToNairaAndKobo,
  formatNumber,
} from "../../../helpers/utilityFunctions";
import {
  EyeOpenMiniIcon,
  EyeSlashIcon,
  WalletIconWithColorBg,
} from "../../icons";
import { CustomHighlight } from "..";

interface TotalVolumeCardProps {
  searchQuery: string | string[];
  volume: string;
  isLoading: boolean;
  isAmount?: boolean;
  backgroundColor?: string;
  cardTitle?: string;
  eyeBackground?: string;
  eyeHoverBackground?: string;
  startLoadingColor?: string;
  endLoadingColor?: string;
  walletIconColor?: string;
}

export default function TotalVolumeCard({
  searchQuery,
  volume,
  isLoading,
  isAmount,
  backgroundColor,
  cardTitle,
  eyeBackground = "#cdbefa",
  eyeHoverBackground = "#b794f4",
  walletIconColor = "#cdbefa",
  startLoadingColor = "#f0ebff",
  endLoadingColor = "#e3defc",
}: Readonly<TotalVolumeCardProps>) {
  const [showVolume, setShowVolume] = useState(true);

  const handleToggleVolumeVisibility = () => {
    setShowVolume((showVolume) => !showVolume);
  };

  const displayVolume = volume || "0.00";

  return (
    <Box
      backgroundColor={backgroundColor || "#F0EBFF"}
      border="1px solid #F0EBFF"
      borderRadius="24px"
      padding={6}
      minH="202px"
      height="100%"
    >
      <HStack spacing={5} mb={9}>
        <WalletIconWithColorBg fill={walletIconColor} />

        <Heading as="h2" fontWeight={700} fontSize="1.125rem" lineHeight="133%">
          <CustomHighlight query={searchQuery}>
            {cardTitle ?? "Total Value"}
          </CustomHighlight>
        </Heading>
      </HStack>

      <Skeleton
        startColor={startLoadingColor}
        endColor={endLoadingColor}
        borderRadius="12px"
        isLoaded={!isLoading}
        w="100%"
        fadeDuration={1}
      >
        <HStack
          justifyContent="space-between"
          alignItems="center"
          gap={4}
          mb="38px"
        >
          <HStack
            color="#000"
            fontSize={{ base: "1.125rem", lg: "1.75rem" }}
            lineHeight="157%"
            alignItems="baseline"
            transition="filter 0.2s"
          >
            {showVolume ? (
              <Text
                fontSize={{ base: "1.25rem", lg: "2.75rem" }}
                lineHeight="132%"
              >
                {isAmount
                  ? `₦ ${formatKoboToNairaAndKobo(parseInt(displayVolume))}`
                  : `${formatNumber(parseInt(displayVolume)).formattedString}`}
              </Text>
            ) : (
              <Text
                h={{ base: "26px", lg: "58px" }}
                fontSize={{ base: "1.25rem", lg: "2.75rem" }}
                lineHeight="132%"
              >
                <span style={{ verticalAlign: "sub" }}>******</span>
              </Text>
            )}
          </HStack>

          <IconButton
            aria-label={showVolume ? "Hide Volume" : "Show Volume"}
            variant="solid"
            bg={eyeBackground}
            borderRadius="50%"
            _hover={{
              bg: eyeHoverBackground,
            }}
            onClick={handleToggleVolumeVisibility}
            icon={showVolume ? <EyeOpenMiniIcon /> : <EyeSlashIcon />}
          />
        </HStack>
      </Skeleton>
    </Box>
  );
}
